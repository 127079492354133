/* istanbul ignore file */
import { warn } from './util/logger'

/* Date and time constants */

export const TIMESTAMP_FORMAT = 'X'
export const HOUR_FORMAT = 'HH:mm'
export const HOUR_FORMAT_WITH_SECONDS = 'HH:mm:ss'
export const DATE_FORMAT = 'YYYY-MM-DD'
export const DATETIME_FORMAT = 'YYYY-MM-DDTHH:mm'
export const DATETIME_FORMAT_WITH_SPACE = 'DD-MM-YYYY HH:mm'
export const DATETIME_FORMAT_WITH_SECONDS = 'YYYY-MM-DDTHH:mm:ss'
export const DATETIME_FORMAT_WITH_SECONDS_WITH_PLUS = 'YYYY-MM-DD+HH:mm:ss'
export const DATETIME_FORMAT_WITH_SECONDS_WITH_SPACE = 'YYYY-MM-DD HH:mm:ss'
export const DATETIME_FORMAT_WITH_MILLISECONDS = 'YYYY-MM-DDTHH:mm:ss.SSS'
export const LOCALE_DATE_FORMAT = 'L'
export const LOCALE_DATETIME_FORMAT = 'L LT'
export const LOCALE_TIME_FORMAT = 'LT'
export const LOCALE_DATETIME_HUMAN_LONG_FORMAT = 'LLLL'
export const LOCALE_DATETIME_HUMAN_LONG_WITHOUTYEAR_FORMAT = 'dddd, D MMMM, LT'
export const LOCALE_DATETIME_HUMAN_SHORT_FORMAT = 'lll'
export const LOCALE_TIME_HUMAN_SHORT_WITHOUTYEAR_FORMAT = 'ddd, DD MMM'
export const LOCALE_DATETIME_HUMAN_SHORT_WITHOUTYEAR_FORMAT = 'ddd, DD MMM, LT'
export const LOCALE_DATE_HUMAN_SHORT_WITHOUTYEAR_FORMAT = 'dddd, DD MMM'
export const LOCALE_DATE_HUMAN_SHORT_FORMAT = 'dddd, DD YYYY MMM'
export const CALENDAR_DATETIME_FORMAT = 'DD MMM, LT'
export const CALENDAR_DATETIME_SHORT_FORMAT = 'DD MMM'
export const YEAR_FORMAT = 'YYYY'
export const YEAR_MONTH_FORMAT = 'MMM YYYY'
export const MONTH_SHORT_FORMAT = 'MMM'
export const MONTH_LONG_FORMAT = 'MMMM'
export const MONTH_FORMAT = 'M'
export const YEAR_MONTH_LONG_FORMAT = 'MMMM YYYY'
export const DAY_OF_MONTH_NUMERIC_FORMAT = 'D'
export const DAY_HUMAN_LONG_FORMAT = 'dddd'
export const MERIDIEM_FORMAT = 'a'
export const HOUR_24_FORMAT = 'HH'
export const HOUR_12_FORMAT = 'hh'
export const MINUTES_FORMAT = 'mm'
export const EXPIRATION_DATE_FORMAT = 'MM/YYYY'
export const TIMEZONE_FORMAT = 'z'
export const TIMEZONE_FORMAT_ABBR = !!getConstant('TIMEZONE_FORMAT_ABBR')

export const HOURS_STEP = 1
export const MINUTES_STEP = 5
export const RENTAL_CAR_HOURS_STEP = 4
export const RENTAL_CAR_MINUTES_STEP = 30

export const HOUR = 'hour'
export const DAY = 'day'

/* Styles constants */

// TODO: Move to theme
export const DEFAULT_HEIGHT = '44px'
export const DEFAULT_PADDING = '2px 5px'
export const DEFAULT_FONT_SIZE = 'medium'
export const DEFAULT_ICON_WIDTH = '30px'

export function getConstant (key) {
    try {
        const constants = getConstantAll()
        if (constants[key] === undefined) {
            throw new Error(`Not found constant ${key}`)
        }
        return constants[key]
    } catch (e) {
        warn(`Not found constant ${key}`)
        return undefined
    }
}

export function getStripeKey (paymentGatewayId = null) {
    const keys = getConstant('PAYMENT_GATEWAYS_KEYS')
    if (keys) {
        return paymentGatewayId && keys['Stripe_' + paymentGatewayId] ? keys['Stripe_' + paymentGatewayId] : Object.values(keys)[0]
    }
    warn('Payment gateway not found!')
    return null
}

/* Environment constants */

export const LOCAL_ENVIRONMENT = 'local'
export const TEST_ENVIRONMENT = 'test'

export const AVAILABLE = 'available'
export const UNAVAILABLE = 'unavailable'
export const STATUS_AVAILABLE = AVAILABLE
export const STATUS_UNAVAILABLE = UNAVAILABLE
export const STATUS_UNAVAILABLE_RATES = 'unavailable rates'
export const STATUS_UNAVAILABLE_PARKING_IS_FULL = 'parking is full'
export const STATUS_UNAVAILABLE_PARKING_IS_CLOSED = 'parking is closed'
export const STATUS_UNAVAILABLE_BOOKING_ADVANCE_TIME_REACHED = 'unavailable advance time reached'
export const STATUS_UNAVAILABLE_BOOKING_MAX_STAY_TIME_REACHED = 'unavailable max stay time reached'
export const STATUS_UNAVAILABLE_BOOKING_MIN_STAY_NOT_REACHED = 'unavailable min stay not reached'
export const STATUS_UNAVAILABLE_RELEASE = [
    STATUS_UNAVAILABLE_BOOKING_ADVANCE_TIME_REACHED,
    STATUS_UNAVAILABLE_BOOKING_MAX_STAY_TIME_REACHED,
    STATUS_UNAVAILABLE_BOOKING_MIN_STAY_NOT_REACHED,
]

export const PARKING_TYPE_AIRPORT = 'airport'
export const PARKING_TYPE_SHARED = 'shared'
export const PARKING_TYPE_VIAT = 'viat'
export const PARKING_TYPE_DEFAULT = 'default'

export const PARKING_TYPES = [
    PARKING_TYPE_AIRPORT,
    PARKING_TYPE_SHARED,
    PARKING_TYPE_VIAT,
    PARKING_TYPE_DEFAULT,
]

export const PARKING_SERVICES_OPENED_24_HOURS = 1

export const PARKING_BOOKING_SERVICE_TYPE_AIRPORT_TRANSFER = 1
export const PARKING_BOOKING_SERVICE_TYPE_PICK_UP = 2
export const PARKING_BOOKING_SERVICE_TYPE_WALKING = 6

export const PARKING_BOOKING_AIRPORT_SERVICES = {
    PARKING_BOOKING_SERVICE_TYPE_AIRPORT_TRANSFER,
    PARKING_BOOKING_SERVICE_TYPE_PICK_UP,
    PARKING_BOOKING_SERVICE_TYPE_WALKING,
}

export const VOID_PARKING_ICON_IMAGE = '/images/parking/void.webp'

export const GOOGLEPLUS_NAME = 'google-el-parking'
export const FACEBOOK_NAME = 'facebook-el-parking'
export const PARKING_PROVIDER_ELPARKING = 0
export const PARKING_PROVIDER_AENA = 2

export const OPENING_MODE_AUTOMATIC = 1
export const OPENING_MODE_WITHOUT_BARRIER = 2
export const OPENING_MODE_WITH_MANUAL_BARRIER = 3
export const OPENING_MODE_VIAT_ENTRY_EXIT = 4
export const OPENING_MODE_VIAT_EXIT = 5
export const OPENING_MODE_CAMERA = 6
export const OPENING_MODE_CAMERA_BLUETOOTH = 7
export const OPENING_MODE_CUSTOM = 30

export const OPENING_MODES = {
    OPENING_MODE_AUTOMATIC,
    OPENING_MODE_WITHOUT_BARRIER,
    OPENING_MODE_WITH_MANUAL_BARRIER,
    OPENING_MODE_VIAT_ENTRY_EXIT,
    OPENING_MODE_VIAT_EXIT,
    OPENING_MODE_CAMERA,
    OPENING_MODE_CAMERA_BLUETOOTH,
    OPENING_MODE_CUSTOM,
}

export const EARLY_ACCESS_OPENING_MODES = [
    OPENING_MODE_CAMERA,
    OPENING_MODE_CAMERA_BLUETOOTH,
    OPENING_MODE_AUTOMATIC,
]

export const SERVICE_GROUP_TYPE_OPTIONAL = 0
export const SERVICE_GROUP_TYPE_MANDATORY = 1

export const PARKING_SERVICE_CAR_VEHICLE = 'parking-for-cars'
export const PARKING_SERVICE_TRUCK_VEHICLE = 'parking-for-trucks'
export const PARKING_SERVICE_MOTORCYCLE_VEHICLE = 'parking-for-motorbikes'
export const PARKING_SERVICE_VANS_VEHICLE = 'parking-for-vans'
export const PARKING_SERVICE_CARAVANS_VEHICLE = 'parking-for-caravans'
export const PARKING_SERVICE_BUS_VEHICLE = 'parking-for-buses'
export const PARKING_SERVICE_BICYCLE_VEHICLE = 'parking-for-bicycles'
export const PARKING_SERVICE_OPEN24 = '24-hours'
export const PARKING_SERVICE_CAR_CHARGE_POINT = 'car-charge-point'
export const PARKING_SERVICE_DISABLED_ACCESS = 'handicapped'

export const PARKING_ORDER_BY_PRICE = 'price'
export const PARKING_ORDER_BY_DISTANCE = 'distance'

export const DISTANCE_MILES_PER_METRE = 0.000621371
export const DISTANCE_FOOTS_PER_MILE = 5280

export const DEFAULT_VEHICLE_INSPECTION_RADIUS_SEARCH_RADIUS = 50
export const DEFAULT_CHARGING_POINTS_SEARCH_RADIUS = 50
export const DEFAULT_AIRPORT_SEARCH_RADIUS = 6
export const DEFAULT_PLACE_SEARCH_RADIUS = getConstant('PARKING_MAP_PLACE_SEARCH_RADIUS') || 2

export const DAYS = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday']

export const ENV_LABEL_WITHOUT_LABEL = 0
export const ENV_LABEL_NO_EMISSIONS = 1
export const ENV_LABEL_ECO_LABEL = 2
export const ENV_LABEL_B_LABEL = 3
export const ENV_LABEL_C_LABEL = 4
export const ENV_LABEL_UNDEFINED = -1

export const ENV_LABELS = {
    ENV_LABEL_WITHOUT_LABEL,
    ENV_LABEL_NO_EMISSIONS,
    ENV_LABEL_ECO_LABEL,
    ENV_LABEL_B_LABEL,
    ENV_LABEL_C_LABEL,
    ENV_LABEL_UNDEFINED,
}

export const MADRID_CENTRAL_NAME = 'Madrid central'
export const MADRID_CENTRAL_ZONES = [
    'ESMADRIBA000003010011',
    'ESMADRIBA000003008012',
    'ESMADRIBA000003007013',
    'ESMADRIBA000003005014',
    'ESMADRIBA000003006015',
    'ESMADRIBA000003001016',
]
export const CONTRACT_TYPE_BOOKING = 1
export const CONTRACT_TYPE_SEASON_TICKET = 2
export const CONTRACT_TYPE_ROTATION = 3
export const CONTRACT_TYPE_PARKING_PASS = 4
export const CONTRACT_TYPE_SER_TICKET = 5
export const CONTRACT_TYPE_CHARGING_POINT_BOOKING = 7
export const CONTRACT_TYPE_VEHICLE_INSPECTION_BOOKING_PASS = 8
export const CONTRACT_TYPE_WORKSHOP_BOOKING = 9

export const SUPPORTED_CONTRACT_TYPES = [
    CONTRACT_TYPE_BOOKING,
    CONTRACT_TYPE_SEASON_TICKET,
    CONTRACT_TYPE_SER_TICKET,
    CONTRACT_TYPE_PARKING_PASS,
    CONTRACT_TYPE_CHARGING_POINT_BOOKING,
    CONTRACT_TYPE_VEHICLE_INSPECTION_BOOKING_PASS,
    CONTRACT_TYPE_ROTATION,
    CONTRACT_TYPE_WORKSHOP_BOOKING,
]

export const ITV_GAS_LPG_CAR = 'gas_lpg_car'
export const ITV_DIESEL_CAR = 'diesel_car'
export const ITV_ELECTRIC_CAR = 'electric_car'
export const ITV_GAS_LPG_VAN = 'gas_lpg_van'
export const ITV_DIESEL_VAN = 'diesel_van'
export const ITV_ELECTRIC_VAN = 'electric_van'
export const ITV_MOTORCICLE = 'motorcycle'

export const ITV_VEHICLE_FILTERS = [
    ITV_GAS_LPG_CAR,
    ITV_DIESEL_CAR,
    ITV_GAS_LPG_VAN,
    ITV_DIESEL_VAN,
    ITV_ELECTRIC_VAN,
    ITV_ELECTRIC_CAR,
    ITV_MOTORCICLE,
]

// Supported fuel types
export const FUEL_TYPE_GASOLINE = 'gasoline'
export const FUEL_TYPE_DIESEL = 'diesel'
export const FUEL_TYPE_LPG = 'lpg'
export const FUEL_TYPE_ELECTRIC = 'electric'

export const FUEL_TYPES = {
    FUEL_TYPE_GASOLINE,
    FUEL_TYPE_DIESEL,
    FUEL_TYPE_LPG,
    FUEL_TYPE_ELECTRIC,
}

// Supported vehicle types
export const VEHICLE_USE_TYPE_RENTING = 'renting'
export const VEHICLE_USE_TYPE_PERSONAL = 'personal'
export const VEHICLE_USE_TYPE_FAMILIAR_FRIEND = 'familiar-friend'
export const VEHICLE_USE_TYPE_CORPORATE = 'corporate'

export const VEHICLE_USER_TYPES = {
    VEHICLE_USE_TYPE_RENTING,
    VEHICLE_USE_TYPE_PERSONAL,
    VEHICLE_USE_TYPE_FAMILIAR_FRIEND,
    VEHICLE_USE_TYPE_CORPORATE,
}

export const VEHICLE_TYPE_TOURING = 'touring'
export const VEHICLE_TYPE_VAN = 'van'
export const VEHICLE_TYPE_MOTORCYCLE = 'motorcycle'
export const VEHICLE_TYPE_TRUCK = 'truck'

export const VEHICLE_TYPES = {
    VEHICLE_TYPE_TOURING,
    VEHICLE_TYPE_VAN,
    VEHICLE_TYPE_MOTORCYCLE,
    VEHICLE_TYPE_TRUCK,
}

// Supported charging point speeds
export const SPEED_CHARGING_FAST = 1
export const SPEED_CHARGING_SEMIFAST = 2
export const SPEED_CHARGING_SLOW = 3

export const SPEED_CHARGING_FAST_DESCRIPTION = 'fast'
export const SPEED_CHARGING_SEMIFAST_DESCRIPTION = 'semifast'
export const SPEED_CHARGING_SLOW_DESCRIPTION = 'slow'

// Supported plugs slugs
export const SCHUKO = 'schuko-eu-plug'
export const MENNEKES = 'mennekes-type-2'
export const CHADEMO = 'chademo-dc'
export const CCS_COMBO = 'ccs-combo-dc'
export const SAE_J1772 = 'sae-j1772-type-1'
export const CEE_2P_E = 'cee-2pe-blue-camping'
export const CEE_3P_E = 'cee-3pe-blue---3-phase'
export const CEE_3P_N_E = 'cee-3pne-red---3-phase'

// Supported plugs names
export const SCHUKO_NAME = 'Schuko'
export const MENNEKES_NAME = 'Mennekes'
export const CHADEMO_NAME = 'CHAdeMO'
export const CCS_COMBO_NAME = 'CCS Combo'
export const SAE_J1772_NAME = 'SAE J1772'
export const CEE_2P_E_NAME = 'CEE 2P+E'
export const CEE_3P_E_NAME = 'CEE 3P+E'
export const CEE_3P_N_E_NAME = 'CEE 3P+N+E'

export const VIAT_DISCOUNT_TYPE_AUTOMATIC = 1
export const VIAT_DISCOUNT_TYPE_REGISTRATION_NEEDED = 2

export const CHARGING_POINT_SUPPORTED_PLUGS = [
    SCHUKO,
    MENNEKES,
    CHADEMO,
    CCS_COMBO,
    SAE_J1772,
    CEE_2P_E,
    CEE_3P_E,
    CEE_3P_N_E,
]
// Maps plugs slugs - plugs names
export const CHARGING_POINT_MAP_SLUG_NAME = {
    [SCHUKO]: SCHUKO_NAME,
    [MENNEKES]: MENNEKES_NAME,
    [CHADEMO]: CHADEMO_NAME,
    [CCS_COMBO]: CCS_COMBO_NAME,
    [SAE_J1772]: SAE_J1772_NAME,
    [CEE_2P_E]: CEE_2P_E_NAME,
    [CEE_3P_E]: CEE_3P_E_NAME,
    [CEE_3P_N_E]: CEE_3P_N_E_NAME,
}

export const CHARGING_POINT_SPEED_FILTER = 'CHARGING_POINT_SPEED_FILTER'
export const CHARGING_POINT_PLUG_TYPE_FILTER = 'CHARGING_POINT_PLUG_TYPE_FILTER'

// Pagatelia Order
export const STATUS_PAGATELIA_ORDER_NOT_PAID = 0
export const STATUS_PAGATELIA_ORDER_PAYMENT_PENDING = 1
export const STATUS_PAGATELIA_ORDER_PAYMENT_BLOCKED = 2
export const STATUS_PAGATELIA_ORDER_PAID = 3
export const STATUS_PAGATELIA_ORDER_SENT = 4
export const STATUS_PAGATELIA_ORDER_COMMITTED = 5
export const STATUS_PAGATELIA_ORDER_RETURNED = 6
export const STATUS_PAGATELIA_ORDER_REFUNDED = 7
export const STATUS_PAGATELIA_ORDER_CANCELED = 8
export const STATUS_PAGATELIA_ORDER_PRESALE = 9
export const STATUS_PAGATELIA_ORDER_PROCESSING = 10
export const STATUS_PAGATELIA_ORDER_PROCESSED = 11

export const STATUS_PAGATELIA = {
    STATUS_PAGATELIA_ORDER_PAYMENT_PENDING,
    STATUS_PAGATELIA_ORDER_PAYMENT_BLOCKED,
    STATUS_PAGATELIA_ORDER_PAID,
    STATUS_PAGATELIA_ORDER_SENT,
    STATUS_PAGATELIA_ORDER_COMMITTED,
    STATUS_PAGATELIA_ORDER_RETURNED,
    STATUS_PAGATELIA_ORDER_REFUNDED,
    STATUS_PAGATELIA_ORDER_CANCELED,
    STATUS_PAGATELIA_ORDER_PRESALE,
    STATUS_PAGATELIA_ORDER_PROCESSING,
    STATUS_PAGATELIA_ORDER_PROCESSED,
    STATUS_PAGATELIA_ORDER_NOT_PAID,
}

// Toll type
export const TOLL_TYPE_CHARGE = 0
export const TOLL_TYPE_MANUAL_CHARGE = 1
export const TOLL_TYPE_PAYMENT = 2
export const TOLL_TYPE_DISCOUNT_PAYMENT = 3

export const TOLL_TYPE = {
    TOLL_TYPE_CHARGE,
    TOLL_TYPE_MANUAL_CHARGE,
    TOLL_TYPE_PAYMENT,
    TOLL_TYPE_DISCOUNT_PAYMENT,
}

// Mobe Status
export const STATUS_MOBE_ACTIVE = 0
export const STATUS_MOBE_ACTIVE_BUT_AWAITING_UNASSIGNING = 1
export const STATUS_MOBE_INACTIVE_AND_UNASSIGNED = 2
export const STATUS_MOBE_INACTIVE_DUE_TO_MISSING_RETURN = 3
export const STATUS_MOBE_INACTIVE_DUE_TO_THEFT = 4
export const STATUS_MOBE_INACTIVE_DUE_TO_LOSS = 5
export const STATUS_MOBE_INACTIVE_DUE_TO_DEFAULT = 6

export const STATUS_MOBE = {
    STATUS_MOBE_ACTIVE,
    STATUS_MOBE_ACTIVE_BUT_AWAITING_UNASSIGNING,
    STATUS_MOBE_INACTIVE_AND_UNASSIGNED,
    STATUS_MOBE_INACTIVE_DUE_TO_MISSING_RETURN,
    STATUS_MOBE_INACTIVE_DUE_TO_THEFT,
    STATUS_MOBE_INACTIVE_DUE_TO_LOSS,
    STATUS_MOBE_INACTIVE_DUE_TO_DEFAULT,
}

// Vehicles section
export const STATUS_DELETE_ERROR_ACTIVE_CONTRACTS = 432
export const STATUS_ERROR_DUPLICATED_NUMBER_PLATE = 465

export const TRADENAME = getConstant('TRADENAME')
export const LONGTRADENAME = getConstant('LONGTRADENAME')
export const LEGALTRADENAME = getConstant('LEGALTRADENAME')
export const FLAVOR = getConstant('FLAVOR')
export const APPLICATION = getConstant('APPLICATION')
export const ZENDESK_URL = getConstant('ZENDESK_URL')
export const DRIVER_PLUS_NAME = getConstant('DRIVER_PLUS_NAME')
export const LOGGABLE_COUNTRIES = Object.values(getConstant('LOGGABLE_COUNTRIES'))
export const RENTAL_CAR_API = getConstant('RENTAL_CAR_API')
export const USER_SERVICE_API_URL = getConstant('USER_SERVICE_API_URL')
export const MUTUAMAS_VIAT_FAQS_URL = getConstant('MUTUAMAS_VIAT_FAQS_URL')
export const MUTUAMAS_VIAT_MODULE_URL = getConstant('MUTUAMAS_VIAT_MODULE_URLS')
export const MUTUAMAS_CASHBACK_URL = getConstant('MUTUAMAS_CASHBACK_URL')
export const MUTUAMAS_SERVICES_CONDITIONS = getConstant('MUTUAMAS_SERVICES_CONDITIONS')

export const TEALIUM_MUTUAMAS_ACCOUNT = getConstant('TEALIUM_MUTUAMAS_ACCOUNT')
export const TEALIUM_MUTUAMAS_PROFILE = getConstant('TEALIUM_MUTUAMAS_PROFILE')
export const TEALIUM_MUTUAMAS_ENV = getConstant('TEALIUM_MUTUAMAS_ENV')

// Workshop products
export const ID_WORKSHOP_PRODUCT_OFFICIAL_CHECK_UP = 1
export const ID_WORKSHOP_PRODUCT_BATTERY = 2
export const ID_WORKSHOP_PRODUCT_WIPER_BLADE = 3
export const ID_WORKSHOP_PRODUCT_SANITATION = 4
export const ID_WORKSHOP_PRODUCT_AIR_CONDITIONING = 5
export const ID_WORKSHOP_PRODUCT_BATTERY_START_STOP = 6
export const ID_WORKSHOP_PRODUCT_PRE_INSPECTION_CHECK_UP = 7

// Workshop
export const ID_WORKSHOP_PROVIDER_MIDAS = 1
export const TWO_FACTOR_AUTH_REQUIRED_STATUS = 439
export const TWO_FACTOR_AUTH_INCORRECT_STATUS = 440
export const TWO_FACTOR_AUTH_EXPIRED_STATUS = 441

export const OTP_HEADER_NAME = 'ep-2fa-code'

export function getConstantAll () {
    return (window && window.App && window.App.Constants) || {}
}

export function getFlag (flag) {
    const FEATURE_FLAGS = getConstant('FEATURE_FLAGS')
    return FEATURE_FLAGS && flag && FEATURE_FLAGS[flag]
}

// Wallet movements
export const WALLET_MOVEMENT_TYPE_DEPOSIT = 'deposit'
export const WALLET_MOVEMENT_TYPE_DEPOSIT_EYSA = 'depositeysa'
export const WALLET_MOVEMENT_TYPE_DEPOSIT_PROMO = 'depositElParkingPromo'
export const WALLET_MOVEMENT_TYPE_DEPOSIT_EMOV = 'depositEmov'
export const WALLET_MOVEMENT_TYPE_DEPOSIT_AUTO = 'depositauto'
export const WALLET_MOVEMENT_TYPE_DEPOSIT_AUTO_FORCEFULLY = 'depositautoforcefully'
export const WALLET_MOVEMENT_TYPE_DEPOSIT_WALLET_DEBT = 'depositwalletdebt'
export const WALLET_MOVEMENT_TYPE_DEPOSIT_MUTUA_MADRILENA = 'depositMutua'
export const WALLET_MOVEMENT_TYPE_DEPOSIT_COUPON = 'depositcoupon'
export const WALLET_MOVEMENT_TYPE_DEPOSIT_COUPON_ORDER_REFUND = 'depositcouponorderrefund'
export const WALLET_MOVEMENT_TYPE_DEPOSIT_USER_REGISTRATION_REWARD = 'depositregistrationreward'
export const WALLET_MOVEMENT_TYPE_DEPOSIT_SPENT_REFUEL_REFUND = 'depositSpentRefuelRefund'
export const WALLET_MOVEMENT_TYPE_DEPOSIT_KIOSK = 'depositKiosk'
export const WALLET_MOVEMENT_TYPE_DEPOSIT_MGM_REWARD_AS_RECOMMENDER = 'depositMgmRewardAsRecommender'
export const WALLET_MOVEMENT_TYPE_DEPOSIT_MGM_REWARD_AS_GUEST = 'depositMgmRewardAsGuest'
export const WALLET_MOVEMENT_TYPE_DEPOSIT_MANUAL_DEPOSIT = 'depositManualDeposit'
export const WALLET_MOVEMENT_TYPE_DEPOSIT_MANUAL_CREDIT = 'depositManualCredit'
export const WALLET_MOVEMENT_TYPE_DEPOSIT_PENDING_USER_DEPOSIT = 'depositPendingUserDeposit'
export const WALLET_MOVEMENT_TYPE_DEPOSIT_BANK_TICKET = 'depositBankTicket'
export const WALLET_MOVEMENT_TYPE_TICKET = 'ticket'
export const WALLET_MOVEMENT_TYPE_TICKET_UNPARKED = 'ticketunparked'
export const WALLET_MOVEMENT_TYPE_BOOKING = 'booking'
export const WALLET_MOVEMENT_TYPE_CONTRACT = 'seasonticketcontract'
export const WALLET_MOVEMENT_TYPE_ROTATION = 'rotation'
export const WALLET_MOVEMENT_TYPE_PAGATELIA_TOLL = 'pagateliaToll'
export const WALLET_MOVEMENT_TYPE_MOBE_QUOTA = 'mobeQuota'
export const WALLET_MOVEMENT_TYPE_MULTIWALLET_FEE = 'multiwalletFee'
export const WALLET_MOVEMENT_TYPE_FINE = 'fine'
export const WALLET_MOVEMENT_TYPE_COUPON_ORDER = 'couponorder'
export const WALLET_MOVEMENT_TYPE_COUPON_ORDER_FEE = 'couponorderfee'
export const WALLET_MOVEMENT_TYPE_REFUEL_TICKET = 'refuelticket'
export const WALLET_MOVEMENT_TYPE_BOOKED_PARKING_PASS = 'bookedParkingPass'
export const WALLET_MOVEMENT_TYPE_POST_REFUEL_ORDER = 'postRefuelOrder'
export const WALLET_MOVEMENT_TYPE_CHARGING_BOOKING = 'chargingBooking'
export const WALLET_MOVEMENT_TYPE_VEHICLE_INSPECTION_BOOKING = 'vehicleInspectionBooking'
export const WALLET_MOVEMENT_TYPE_FINES_NOTIFIER_SUBSCRIPTION = 'finesNotifierSubscription'
export const WALLET_MOVEMENT_TYPE_TAKSEE_TRANSACTION = 'takseeTransaction'
export const WALLET_MOVEMENT_TYPE_WORKSHOP_BOOKING = 'workshopBooking'

export const WALLET_MOVEMENTS = {
    WALLET_MOVEMENT_TYPE_DEPOSIT,
    WALLET_MOVEMENT_TYPE_DEPOSIT_EYSA,
    WALLET_MOVEMENT_TYPE_DEPOSIT_PROMO,
    WALLET_MOVEMENT_TYPE_DEPOSIT_EMOV,
    WALLET_MOVEMENT_TYPE_DEPOSIT_AUTO,
    WALLET_MOVEMENT_TYPE_DEPOSIT_AUTO_FORCEFULLY,
    WALLET_MOVEMENT_TYPE_DEPOSIT_WALLET_DEBT,
    WALLET_MOVEMENT_TYPE_DEPOSIT_MUTUA_MADRILENA,
    WALLET_MOVEMENT_TYPE_DEPOSIT_COUPON,
    WALLET_MOVEMENT_TYPE_DEPOSIT_COUPON_ORDER_REFUND,
    WALLET_MOVEMENT_TYPE_DEPOSIT_USER_REGISTRATION_REWARD,
    WALLET_MOVEMENT_TYPE_DEPOSIT_SPENT_REFUEL_REFUND,
    WALLET_MOVEMENT_TYPE_DEPOSIT_KIOSK,
    WALLET_MOVEMENT_TYPE_DEPOSIT_MGM_REWARD_AS_RECOMMENDER,
    WALLET_MOVEMENT_TYPE_DEPOSIT_MGM_REWARD_AS_GUEST,
    WALLET_MOVEMENT_TYPE_DEPOSIT_MANUAL_DEPOSIT,
    WALLET_MOVEMENT_TYPE_DEPOSIT_MANUAL_CREDIT,
    WALLET_MOVEMENT_TYPE_DEPOSIT_PENDING_USER_DEPOSIT,
    WALLET_MOVEMENT_TYPE_DEPOSIT_BANK_TICKET,
    WALLET_MOVEMENT_TYPE_TICKET,
    WALLET_MOVEMENT_TYPE_TICKET_UNPARKED,
    WALLET_MOVEMENT_TYPE_BOOKING,
    WALLET_MOVEMENT_TYPE_CONTRACT,
    WALLET_MOVEMENT_TYPE_ROTATION,
    WALLET_MOVEMENT_TYPE_PAGATELIA_TOLL,
    WALLET_MOVEMENT_TYPE_MOBE_QUOTA,
    WALLET_MOVEMENT_TYPE_MULTIWALLET_FEE,
    WALLET_MOVEMENT_TYPE_FINE,
    WALLET_MOVEMENT_TYPE_COUPON_ORDER,
    WALLET_MOVEMENT_TYPE_COUPON_ORDER_FEE,
    WALLET_MOVEMENT_TYPE_REFUEL_TICKET,
    WALLET_MOVEMENT_TYPE_BOOKED_PARKING_PASS,
    WALLET_MOVEMENT_TYPE_POST_REFUEL_ORDER,
    WALLET_MOVEMENT_TYPE_CHARGING_BOOKING,
    WALLET_MOVEMENT_TYPE_VEHICLE_INSPECTION_BOOKING,
    WALLET_MOVEMENT_TYPE_FINES_NOTIFIER_SUBSCRIPTION,
    WALLET_MOVEMENT_TYPE_TAKSEE_TRANSACTION,
    WALLET_MOVEMENT_TYPE_WORKSHOP_BOOKING,
}

// Type Fines
export const SER_FINE = 'ser-fine'
export const PARKING_FINE = 'parking-fine'
export const SER_FINE_ERROR_NOT_FOUND = 490
export const SER_FINE_ERROR_CANCEL_ALREADY = 486
export const SER_FINE_ERROR_NOT_CANCELLABLE = 480
export const SER_FINE_ERROR_OUT_OF_DATE = 488
export const SER_FINE_ERROR_UNABLE_TO_CANCEL_NON_CANCELLABLE = 489
export const SER_FINE_ERROR_NOT_CANCELLABLE_WEB = 493

// Refuel Ticket Status
export const REFUEL_TICKET_STATUS_PROCESSING = 0
export const REFUEL_TICKET_STATUS_READY_TO_USE = 10
export const REFUEL_TICKET_STATUS_CANCELLED = 20
export const REFUEL_TICKET_STATUS_REDEEMED = 30
export const REFUEL_TICKET_STATUS_EXPIRED = 40
export const REFUEL_TICKET_STATUS_CANCELLED_EXPIRED = 50

export const REFUEL_TICKET_STATUS = {
    REFUEL_TICKET_STATUS_PROCESSING,
    REFUEL_TICKET_STATUS_READY_TO_USE,
    REFUEL_TICKET_STATUS_CANCELLED,
    REFUEL_TICKET_STATUS_REDEEMED,
    REFUEL_TICKET_STATUS_EXPIRED,
    REFUEL_TICKET_STATUS_CANCELLED_EXPIRED,
}

// Booked Parking Pass Status

export const BOOKED_PARKING_PASS_STATUS_REVOKED = 1
export const BOOKED_PARKING_PASS_STATUS_CANCELLED = 2

// Fines Notifier Prices Types
export const FINES_NOTIFIER_PRICES_TYPE_DNI = 1
export const FINES_NOTIFIER_PRICES_TYPE_NUMBER_PLATE = 2
export const FINES_NOTIFIER_PRICES_STATUS_ACTIVE = 2

// Rental Car
export const RENTAL_CAR_VEHICLE_TYPE_CAR = 'car'
export const RENTAL_CAR_VEHICLE_TYPE_VAN = 'van'
export const RENTAL_CAR_POI_TYPE_AIRPORT = 'airport'
export const RENTAL_CAR_POI_TYPE_STATION = 'station'
export const RENTAL_CAR_POI_TYPE_CITY = 'city'
export const RENTAL_CAR_POI_TYPE_LOCALITY = 'locality'
export const RENTAL_CAR_CLICK_TO_CALL_REGISTER = 'register'
export const RENTAL_CAR_CLICK_TO_CALL_INFO = 'info'
export const RENTAL_CAR_CLICK_TO_CALL_RECOVER_CART = 'recover-cart'
export const RENTAL_CAR_CLICK_TO_CALL_NO_RESULTS = 'no-results'
export const RENTAL_CAR_FORMAT_LOCALE_DATE = 'YYYY/MM/DD HH:mm'
export const RENTAL_CAR_VEHICLE_SUBCATEGORY_ECO = 'economic'
export const RENTAL_CAR_VEHICLE_SUBCATEGORY_SMALL = 'small'
export const RENTAL_CAR_VEHICLE_SUBCATEGORY_COMPACT = 'compact'
export const RENTAL_CAR_VEHICLE_SUBCATEGORY_BIG = 'big'
export const RENTAL_CAR_VEHICLE_SUBCATEGORY_SELECTION = 'selection'
export const RENTAL_CAR_VEHICLE_FUEL_TYPE_GASOLINE = 'gasoline'
export const RENTAL_CAR_VEHICLE_FUEL_TYPE_DIESEL = 'diesel'
export const RENTAL_CAR_VEHICLE_FUEL_TYPE_ELECTRIC = 'electric'
export const RENTAL_CAR_VEHICLE_FUEL_TYPE_HYBRID = 'hybrid'
export const RENTAL_CAR_VEHICLE_GEAR_BOX_MANUAL = 'manual'
export const RENTAL_CAR_VEHICLE_GEAR_BOX_AUTOMATIC = 'automatic'

export const RENTAL_CAR_CONTRACT_ACTIVE = 20
export const RENTAL_CAR_CONTRACT_ACTIVE_UNCONFIRMED = 10
export const RENTAL_CAR_CONTRACT_FINISHED = 30
export const RENTAL_CAR_CONTRACT_CANCELLED_BY_USER = -20
export const RENTAL_CAR_CONTRACT_CANCELLED_BY_OPERATOR = -40
export const RENTAL_CAR_CONTRACT_CANCELLED_BY_SUPPLIER = -50

export const RENTAL_CAR_CONTRACT_STATUS = {
    RENTAL_CAR_CONTRACT_ACTIVE,
    RENTAL_CAR_CONTRACT_ACTIVE_UNCONFIRMED,
    RENTAL_CAR_CONTRACT_FINISHED,
    RENTAL_CAR_CONTRACT_CANCELLED_BY_USER,
    RENTAL_CAR_CONTRACT_CANCELLED_BY_OPERATOR,
    RENTAL_CAR_CONTRACT_CANCELLED_BY_SUPPLIER,
}

export const SER_FINE_CANCEL_TYPE_BULLETIN = 1
export const SER_FINE_CANCEL_TYPE_NUMBER_PLATE = 2

export const FIREBASE_WEB_LINK_EL_PARKING = getConstant('FIREBASE_WEB_LINK_EL_PARKING')

export const DEBOUNCED_INPUT_TIME = 500
