import { BookingInThePastError } from 'commons/js/client/errors'
import { BOOKING_IN_THE_PAST_ERROR, NUMBER_PLATE_NOT_IN_WHITELIST } from '../constants'
import { NumberPlateNotInWhiteListError, UnprocessableEntityError } from '../errors'
import { buildClientError } from '../helpers'
import { buildBookingService } from './booking-service'
import { buildBookingErrorData } from './error'
import { buildVehicle } from './vehicle'

export function mapBooking ({ bookingId }) {
    return {
        idBooking: bookingId,
    }
}

export function buildBooking (booking) {
    return {
        id: booking.id,
        idExternal: booking.id_external,
        cancellable: booking.isCancelable,
        cancelableLimitDate: booking.cancelableLimitDate,
        canExtend: booking.can_extend,
        startDate: booking.start_date,
        endDate: booking.end_date,
        bookingProduct: booking.bookingProduct,
        bookingInstructions: booking.booking_instructions,
        refunded: booking.refunded,
        priceQty: booking.price_qty,
        priceBaseQty: booking.base_price_no_commission_qty,
        commissionQty: booking.commission_qty,
        parking: booking.parking,
        vehicle: booking.vehicle ? buildVehicle(booking.vehicle) : null,
        bookingAddedServices: booking.bookingAddedServices.map(buildBookingService),
        isPostPayment: booking.isPostPayment,
        bookingPromotionalCode: booking.bookingPromotionalCode,
        promotionalCodePrice: booking.promotional_code_discounted_price,
        canceled: booking.canceled,
        canEdit: booking.can_edit,
        canEntryInAdvance: booking.can_entry_in_advance,
        entryInAdvanceMinutes: booking.entry_in_advance_minutes,
        wallet: booking.wallet && {
            name: booking.wallet.name,
            id: booking.wallet.id,
            isAdmin: booking.wallet.is_admin,
            qty: booking.wallet.qty,
        },
        user: booking.user,
    }
}

export function buildBookingError (error) {
    if (error instanceof NumberPlateNotInWhiteListError) {
        return {
            name: NUMBER_PLATE_NOT_IN_WHITELIST,
            status: 461,
            data: buildBookingErrorData(error),
        }
    }
    if (error instanceof UnprocessableEntityError) {
        return {
            ...buildClientError(error),
            status: error.response.status,
            data: buildBookingErrorData(error),
        }
    }
    if (error instanceof BookingInThePastError) {
        return {
            name: BOOKING_IN_THE_PAST_ERROR,
            status: 453,
        }
    }

    return buildClientError(error)
}
