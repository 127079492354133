import Routes from '../routes/routes'

import { buildGetApi } from './helpers'

import buildOptions from './buildApiOptions'
import { mapBookingAvailability, buildClientError } from './converters'
import { UnprocessableEntityError } from 'commons/js/api/errors'
import { BOOKING_IN_THE_PAST_ERROR } from './constants'
import { BookingInThePastError } from '../client/errors'
function buildBookingAvailabilityErrorData (error) {
    const data = { ...error.response.data }

    return {
        ...data,
        promoCode: data.promotional_code,
        minPrice: data.code_min_amount,
    }
}

function buildBookingAvailabilityError (error) {
    if (error instanceof UnprocessableEntityError) {
        return {
            ...buildClientError(error),
            status: error.response.status,
            data: buildBookingAvailabilityErrorData(error),
        }
    } else if (error instanceof BookingInThePastError) {
        return {
            name: BOOKING_IN_THE_PAST_ERROR,
            status: 453,
        }
    }

    return buildClientError(error)
}

function buildBookingPromotionalCode (bookingPromotionalCode) {
    return {
        code: bookingPromotionalCode.code,
        title: bookingPromotionalCode.description.title,
        description: bookingPromotionalCode.description.description,
        conditionsUrl: bookingPromotionalCode.description.url_conditions,
    }
}

function buildBookingProduct (bookingProduct) {
    return {
        name: bookingProduct.name,
    }
}

function buildBookingAvailability (parkingAvailability) {
    return {
        baseQty: parkingAvailability.base_price_no_commission_qty,
        commissionQty: parkingAvailability.commission_qty,
        priceQty: parkingAvailability.price_qty,
        subtotalQty: {
            ...parkingAvailability.price_qty,
            amount: parkingAvailability.base_price_no_commission_qty.amount,
        },
        promotionalCodeQty: parkingAvailability.promotional_code_discounted_price,
        subtotal: parkingAvailability.base_price_no_commission_qty.amount,
        vatAmount: parkingAvailability.vat_type,
        totalAmount: parkingAvailability.price_qty.amount,
        vatType: parkingAvailability.vat_type,
        bookingsOverlaped: parkingAvailability.bookingsOverlaped,
        bookingPromotionalCode: parkingAvailability.bookingPromotionalCode && buildBookingPromotionalCode(parkingAvailability.bookingPromotionalCode),
        bookingProduct: parkingAvailability.bookingProduct && buildBookingProduct(parkingAvailability.bookingProduct),
        isPostPayment: parkingAvailability.bookingAvailability && parkingAvailability.bookingAvailability.isPostPayment,
        effectiveEndDate: parkingAvailability.bookingAvailability && parkingAvailability.bookingAvailability.effective_end_date,
    }
}
export default buildGetApi({
    route: Routes.apiGetBookingAvailability,
    buildResult: buildBookingAvailability,
    mapParams: mapBookingAvailability,
    buildOptions,
    buildError: buildBookingAvailabilityError,
})
