// be careful!
// generated file with Yii routes with webpack. Don't update lines here!
export const json = {
    "user-service\/register-intent-success": {
        "original_route": "pl\/user-service\/register-intent-success",
        "route": "pl\/user-service\/register-intent-success",
        "params": []
    },
    "keycloak\/contains": {
        "original_route": "pl\/keycloak\/contains",
        "route": "pl\/keycloak\/contains",
        "params": []
    },
    "user\/account\/request-password-reset-for-social-network": {
        "original_route": "pl\/social-network-access\/<socialNetwork>",
        "route": "pl\/social-network-access\/:socialNetwork",
        "params": [
            "socialNetwork"
        ]
    },
    "site\/error": {
        "original_route": "pl\/error",
        "route": "pl\/error",
        "params": []
    },
    "ser-fine\/show-detail": {
        "original_route": "GET pl\/ser\/complaint\/<idSERFine:\\w>\/detail",
        "route": "pl\/ser\/complaint\/:idSERFine\/detail",
        "params": [
            "idSERFine"
        ]
    },
    "user\/user\/create": {
        "original_route": "POST pl\/user",
        "route": "pl\/user",
        "params": []
    },
    "user\/user\/update": {
        "original_route": "PUT pl\/user\/<id:\\d+>",
        "route": "pl\/user\/:id",
        "params": [
            "id"
        ]
    },
    "user\/user\/update-basic-location": {
        "original_route": "PUT pl\/user-basic\/<id:\\d+>",
        "route": "pl\/user-basic\/:id",
        "params": [
            "id"
        ]
    },
    "account\/logout": {
        "original_route": "pl\/disconnect",
        "route": "pl\/disconnect",
        "params": []
    },
    "user\/account\/request-delete-user": {
        "original_route": "PUT pl\/remove-user",
        "route": "pl\/remove-user",
        "params": []
    },
    "user\/account\/delete": {
        "original_route": "pl\/remove-user\/<token>",
        "route": "pl\/remove-user\/:token",
        "params": [
            "token"
        ]
    },
    "account\/edit": {
        "original_route": "pl\/my-account",
        "route": "pl\/my-account",
        "params": []
    },
    "account\/user": {
        "original_route": "pl\/my-details",
        "route": "pl\/my-details",
        "params": []
    },
    "account\/security": {
        "original_route": "pl\/security",
        "route": "pl\/security",
        "params": []
    },
    "user\/account\/social-login": {
        "original_route": "pl\/account\/social-login\/<social_network_name>",
        "route": "pl\/account\/social-login\/:social_network_name",
        "params": [
            "social_network_name"
        ]
    },
    "glovie\/vehicles": {
        "original_route": "pl\/my-cars",
        "route": "pl\/my-cars",
        "params": []
    },
    "glovie\/vehicles-page": {
        "original_route": "pl\/my-cars\/<page:\\d+>",
        "route": "pl\/my-cars\/:page",
        "params": [
            "page"
        ]
    },
    "glovie\/trailers": {
        "original_route": "pl\/my-trailers",
        "route": "pl\/my-trailers",
        "params": []
    },
    "glovie\/trailers-page": {
        "original_route": "pl\/my-trailers\/<page:\\d+>",
        "route": "pl\/my-trailers\/:page",
        "params": [
            "page"
        ]
    },
    "user\/account\/password-reset": {
        "original_route": "POST pl\/recover-password",
        "route": "pl\/recover-password",
        "params": []
    },
    "account\/request-password-reset": {
        "original_route": "pl\/recover-password",
        "route": "pl\/recover-password",
        "params": []
    },
    "user\/account\/reset-password-update": {
        "original_route": "POST pl\/change-password\/<token>",
        "route": "pl\/change-password\/:token",
        "params": [
            "token"
        ]
    },
    "user\/account\/reset-password": {
        "original_route": "pl\/change-password\/<token>",
        "route": "pl\/change-password\/:token",
        "params": [
            "token"
        ]
    },
    "user\/account\/email-update-request": {
        "original_route": "pl\/email-change-request",
        "route": "pl\/email-change-request",
        "params": []
    },
    "account\/update-email": {
        "original_route": "pl\/change-email\/<token>\/<newEmail>",
        "route": "pl\/change-email\/:token\/:newEmail",
        "params": [
            "token",
            "newEmail"
        ]
    },
    "user\/account\/confirm": {
        "original_route": "pl\/change-email-confirm\/<code>",
        "route": "pl\/change-email-confirm\/:code",
        "params": [
            "code"
        ]
    },
    "account\/login": {
        "original_route": "pl\/connect",
        "route": "pl\/connect",
        "params": []
    },
    "account\/end-process": {
        "original_route": "pl\/end-process",
        "route": "pl\/end-process",
        "params": []
    },
    "account\/register": {
        "original_route": "pl\/register",
        "route": "pl\/register",
        "params": []
    },
    "user\/user\/change-password": {
        "original_route": "pl\/change-password",
        "route": "pl\/change-password",
        "params": []
    },
    "site\/terms": {
        "original_route": "pl\/legal-terms",
        "route": "pl\/legal-terms",
        "params": []
    },
    "site\/terms-cookies": {
        "original_route": "pl\/privacy-policy-cookies",
        "route": "pl\/privacy-policy-cookies",
        "params": []
    },
    "site\/transparency": {
        "original_route": "pl\/przezroczysto\u015b\u0107",
        "route": "pl\/przezroczysto\u015b\u0107",
        "params": []
    },
    "site\/security-policy": {
        "original_route": "pl\/security-policy",
        "route": "pl\/security-policy",
        "params": []
    },
    "account\/validate": {
        "original_route": "pl\/validate\/<token>",
        "route": "pl\/validate\/:token",
        "params": [
            "token"
        ]
    },
    "glovie\/pagatelia-order": {
        "original_route": "pl\/order\/viat\/<id:\\d+>",
        "route": "pl\/order\/viat\/:id",
        "params": [
            "id"
        ]
    },
    "order\/list": {
        "original_route": "pl\/orders",
        "route": "pl\/orders",
        "params": []
    },
    "order\/page": {
        "original_route": "pl\/orders\/<page:\\d+>",
        "route": "pl\/orders\/:page",
        "params": [
            "page"
        ]
    },
    "mobe\/list": {
        "original_route": "pl\/orders\/viat",
        "route": "pl\/orders\/viat",
        "params": []
    },
    "mobe\/page": {
        "original_route": "pl\/orders\/viat\/<page:\\d+>",
        "route": "pl\/orders\/viat\/:page",
        "params": [
            "page"
        ]
    },
    "toll\/list": {
        "original_route": "pl\/orders\/tolls",
        "route": "pl\/orders\/tolls",
        "params": []
    },
    "toll\/page": {
        "original_route": "pl\/orders\/tolls\/<page:\\d+>",
        "route": "pl\/orders\/tolls\/:page",
        "params": [
            "page"
        ]
    },
    "mobe\/update-vehicle": {
        "original_route": "PUT pl\/mobe\/<id:\\w*>\/vehicle\/<vehicleId:\\d+>",
        "route": "pl\/mobe\/:id\/vehicle\/:vehicleId",
        "params": [
            "id",
            "vehicleId"
        ]
    },
    "mobe\/unbind-vehicle": {
        "original_route": "PUT pl\/mobe\/<id:\\w*>\/unbind-vehicle",
        "route": "pl\/mobe\/:id\/unbind-vehicle",
        "params": [
            "id"
        ]
    },
    "mobe\/unassign": {
        "original_route": "PUT pl\/mobe\/<id:\\w*>\/unassign",
        "route": "pl\/mobe\/:id\/unassign",
        "params": [
            "id"
        ]
    },
    "mobe\/search": {
        "original_route": "GET pl\/mobe\/search",
        "route": "pl\/mobe\/search",
        "params": []
    },
    "toll\/search": {
        "original_route": "GET pl\/toll\/search",
        "route": "pl\/toll\/search",
        "params": []
    },
    "mobe\/update-wallet": {
        "original_route": "PUT pl\/mobe\/<idMobe:\\w+>\/wallet\/<idWallet:\\d+>",
        "route": "pl\/mobe\/:idMobe\/wallet\/:idWallet",
        "params": [
            "idMobe",
            "idWallet"
        ]
    },
    "site\/landing-ora": {
        "original_route": "GET pl\/regulated-mobile-parking-payment",
        "route": "pl\/regulated-mobile-parking-payment",
        "params": []
    },
    "ser-ticket\/show-detail": {
        "original_route": "GET pl\/ticket\/<idSERTicket:\\w+>\/detail",
        "route": "pl\/ticket\/:idSERTicket\/detail",
        "params": [
            "idSERTicket"
        ]
    },
    "ser-ticket\/send-email": {
        "original_route": "GET pl\/ticket\/<idSERTicket:\\w+>\/send-email",
        "route": "pl\/ticket\/:idSERTicket\/send-email",
        "params": [
            "idSERTicket"
        ]
    },
    "ser-ticket\/download-pdf": {
        "original_route": "GET pl\/ticket\/<idSERTicket:\\w+>.pdf",
        "route": "pl\/ticket\/:idSERTicket.pdf",
        "params": [
            "idSERTicket"
        ]
    },
    "ser-ticket\/pay": {
        "original_route": "pl\/park-in-<slug>\/take-ticket",
        "route": "pl\/park-in-:slug\/take-ticket",
        "params": [
            "slug"
        ]
    },
    "ser-ticket\/create": {
        "original_route": "POST pl\/ticket-be\/get-ticket",
        "route": "pl\/ticket-be\/get-ticket",
        "params": []
    },
    "ser-ticket\/unpark": {
        "original_route": "POST pl\/ticket\/<id>\/unpark",
        "route": "pl\/ticket\/:id\/unpark",
        "params": [
            "id"
        ]
    },
    "ser-ticket\/check-unpark": {
        "original_route": "GET pl\/ticket\/<id>\/unpark",
        "route": "pl\/ticket\/:id\/unpark",
        "params": [
            "id"
        ]
    },
    "ser-ticket\/new": {
        "original_route": "pl\/new\/<id>\/ticket",
        "route": "pl\/new\/:id\/ticket",
        "params": [
            "id"
        ]
    },
    "wallet\/payment-method": {
        "original_route": "pl\/wallet\/payment-method",
        "route": "pl\/wallet\/payment-method",
        "params": []
    },
    "wallet\/my-wallet": {
        "original_route": "pl\/wallet",
        "route": "pl\/wallet",
        "params": []
    },
    "wallet\/show": {
        "original_route": "pl\/wallet\/<idWallet:\\d+>\/show",
        "route": "pl\/wallet\/:idWallet\/show",
        "params": [
            "idWallet"
        ]
    },
    "wallet\/data": {
        "original_route": "pl\/wallet\/<idWallet:\\d+>\/data",
        "route": "pl\/wallet\/:idWallet\/data",
        "params": [
            "idWallet"
        ]
    },
    "wallet\/beneficiaries": {
        "original_route": "pl\/wallet\/<idWallet:\\d+>\/beneficjenci",
        "route": "pl\/wallet\/:idWallet\/beneficjenci",
        "params": [
            "idWallet"
        ]
    },
    "glovie\/wallet": {
        "original_route": "pl\/wallets",
        "route": "pl\/wallets",
        "params": []
    },
    "wallet\/pay-debt": {
        "original_route": "PUT pl\/wallet\/pay-debt",
        "route": "pl\/wallet\/pay-debt",
        "params": []
    },
    "wallet\/create-deposit": {
        "original_route": "POST pl\/wallet\/top-up",
        "route": "pl\/wallet\/top-up",
        "params": []
    },
    "wallet\/confirm-payment-intent": {
        "original_route": "POST pl\/wallet\/confirm-payment-intent",
        "route": "pl\/wallet\/confirm-payment-intent",
        "params": []
    },
    "wallet\/associate-credit-card": {
        "original_route": "POST pl\/wallet\/<idWallet:\\d+>\/associate-credit-card\/<idCreditCard:\\d+>",
        "route": "pl\/wallet\/:idWallet\/associate-credit-card\/:idCreditCard",
        "params": [
            "idWallet",
            "idCreditCard"
        ]
    },
    "invoice\/pdf": {
        "original_route": "GET pl\/billing\/invoices\/<invoiceUid>.pdf",
        "route": "pl\/billing\/invoices\/:invoiceUid.pdf",
        "params": [
            "invoiceUid"
        ]
    },
    "invoice\/view-xlsx": {
        "original_route": "GET pl\/download-billing\/<invoiceUid>.xlsx",
        "route": "pl\/download-billing\/:invoiceUid.xlsx",
        "params": [
            "invoiceUid"
        ]
    },
    "invoice\/list": {
        "original_route": "GET pl\/billing\/invoices",
        "route": "pl\/billing\/invoices",
        "params": []
    },
    "invoice\/page": {
        "original_route": "GET pl\/billing\/invoices\/<page:\\d+>",
        "route": "pl\/billing\/invoices\/:page",
        "params": [
            "page"
        ]
    },
    "invoice\/update": {
        "original_route": "PUT pl\/billing\/data",
        "route": "pl\/billing\/data",
        "params": []
    },
    "parking\/list": {
        "original_route": "GET pl\/search-for-parking-space",
        "route": "pl\/search-for-parking-space",
        "params": []
    },
    "parking\/list-aeroparking": {
        "original_route": "GET pl\/search-airport-parking",
        "route": "pl\/search-airport-parking",
        "params": []
    },
    "parking\/list-season-ticket": {
        "original_route": "GET pl\/search-subscription",
        "route": "pl\/search-subscription",
        "params": []
    },
    "parking\/search-season-ticket": {
        "original_route": "POST pl\/search-subscription",
        "route": "pl\/search-subscription",
        "params": []
    },
    "glovie\/list-actives": {
        "original_route": "GET pl\/glovie\/list-actives",
        "route": "pl\/glovie\/list-actives",
        "params": []
    },
    "glovie\/list-inactives": {
        "original_route": "GET pl\/glovie\/list-inactives\/<page:\\d+>",
        "route": "pl\/glovie\/list-inactives\/:page",
        "params": [
            "page"
        ]
    },
    "booking\/process": {
        "original_route": "GET pl\/confirm-booking\/<idPrebooking:\\d+>",
        "route": "pl\/confirm-booking\/:idPrebooking",
        "params": [
            "idPrebooking"
        ]
    },
    "vehicle-inspection-booking\/process": {
        "original_route": "GET pl\/confirm-booking-vehicle-inspection",
        "route": "pl\/confirm-booking-vehicle-inspection",
        "params": []
    },
    "booking\/show": {
        "original_route": "GET pl\/reservation\/<idBooking:\\d+>\/view",
        "route": "pl\/reservation\/:idBooking\/view",
        "params": [
            "idBooking"
        ]
    },
    "booking\/new": {
        "original_route": "GET pl\/reservation\/<idBooking:\\d+>\/new",
        "route": "pl\/reservation\/:idBooking\/new",
        "params": [
            "idBooking"
        ]
    },
    "booking\/cancel": {
        "original_route": "PUT pl\/reservation\/cancel\/<idBooking:\\d+>",
        "route": "pl\/reservation\/cancel\/:idBooking",
        "params": [
            "idBooking"
        ]
    },
    "vehicle-brand\/vehicle-models": {
        "original_route": "GET pl\/vehicle-brand\/<brandId:\\d+>\/models",
        "route": "pl\/vehicle-brand\/:brandId\/models",
        "params": [
            "brandId"
        ]
    },
    "vehicle\/index": {
        "original_route": "GET pl\/vehicle",
        "route": "pl\/vehicle",
        "params": []
    },
    "vehicle\/view": {
        "original_route": "GET pl\/vehicle\/<vehicleid:\\d+>",
        "route": "pl\/vehicle\/:vehicleid",
        "params": [
            "vehicleid"
        ]
    },
    "vehicle\/delete": {
        "original_route": "DELETE pl\/vehicle\/<vehicleId:\\d+>",
        "route": "pl\/vehicle\/:vehicleId",
        "params": [
            "vehicleId"
        ]
    },
    "vehicle\/create": {
        "original_route": "POST pl\/vehicle",
        "route": "pl\/vehicle",
        "params": []
    },
    "vehicle\/update": {
        "original_route": "PUT pl\/vehicle\/<vehicleId:\\d+>",
        "route": "pl\/vehicle\/:vehicleId",
        "params": [
            "vehicleId"
        ]
    },
    "season-ticket\/contract": {
        "original_route": "GET pl\/subscription\/<seasonTicketId:\\d+>\/get",
        "route": "pl\/subscription\/:seasonTicketId\/get",
        "params": [
            "seasonTicketId"
        ]
    },
    "season-ticket-contract\/show-detail": {
        "original_route": "GET pl\/subscription\/payment\/<id:\\d+>\/details",
        "route": "pl\/subscription\/payment\/:id\/details",
        "params": [
            "id"
        ]
    },
    "season-ticket-contract\/show": {
        "original_route": "GET pl\/subscription\/payment\/<id:\\d+>\/view",
        "route": "pl\/subscription\/payment\/:id\/view",
        "params": [
            "id"
        ]
    },
    "season-ticket-contract\/new": {
        "original_route": "GET pl\/subscription\/payment\/<id:\\d+>\/new",
        "route": "pl\/subscription\/payment\/:id\/new",
        "params": [
            "id"
        ]
    },
    "season-ticket-contract\/create": {
        "original_route": "POST pl\/subscription",
        "route": "pl\/subscription",
        "params": []
    },
    "season-ticket-contract\/update-vehicle": {
        "original_route": "PUT pl\/subscription\/<contractId:\\d+>\/vehicle\/<vehicleId:\\d+>",
        "route": "pl\/subscription\/:contractId\/vehicle\/:vehicleId",
        "params": [
            "contractId",
            "vehicleId"
        ]
    },
    "api\/season-ticket-contract\/cancel": {
        "original_route": "DELETE pl\/subscription\/<idContract:\\d+>",
        "route": "pl\/subscription\/:idContract",
        "params": [
            "idContract"
        ]
    },
    "site\/operators": {
        "original_route": "pl\/operators",
        "route": "pl\/operators",
        "params": []
    },
    "glovie\/preferences": {
        "original_route": "GET pl\/preferences",
        "route": "pl\/preferences",
        "params": []
    },
    "user-preferences\/update": {
        "original_route": "PUT pl\/preferences\/<id:\\d+>",
        "route": "pl\/preferences\/:id",
        "params": [
            "id"
        ]
    },
    "glovie\/privacy": {
        "original_route": "GET pl\/privacy",
        "route": "pl\/privacy",
        "params": []
    },
    "user\/privacy\/create-update": {
        "original_route": "PUT pl\/privacy",
        "route": "pl\/privacy",
        "params": []
    },
    "wallet-preferences\/index": {
        "original_route": "GET pl\/wallet-preferences",
        "route": "pl\/wallet-preferences",
        "params": []
    },
    "wallet-preferences\/update": {
        "original_route": "PUT pl\/wallet-preferences\/<id:\\d+>",
        "route": "pl\/wallet-preferences\/:id",
        "params": [
            "id"
        ]
    },
    "airport\/show": {
        "original_route": "GET pl\/park-in-airport-<slug>\/<id:\\d+>",
        "route": "pl\/park-in-airport-:slug\/:id",
        "params": [
            "slug",
            "id"
        ]
    },
    "town\/show": {
        "original_route": "GET pl\/park-in-<slug>",
        "route": "pl\/park-in-:slug",
        "params": [
            "slug"
        ]
    },
    "ser-zone\/show": {
        "original_route": "GET pl\/zone\/<zoneid>\/vehicle\/<vehicleid:\\d+>",
        "route": "pl\/zone\/:zoneid\/vehicle\/:vehicleid",
        "params": [
            "zoneid",
            "vehicleid"
        ]
    },
    "glovie\/fines": {
        "original_route": "GET pl\/glovie\/fines",
        "route": "pl\/glovie\/fines",
        "params": []
    },
    "ser-fine\/check": {
        "original_route": "POST pl\/ser-fine\/check",
        "route": "pl\/ser-fine\/check",
        "params": []
    },
    "shop\/shop\/invoice": {
        "original_route": "pl\/invoice\/<uid>",
        "route": "pl\/invoice\/:uid",
        "params": [
            "uid"
        ]
    },
    "user\/account\/unsubscribe-from-commercials": {
        "original_route": "pl\/unsubscribe\/<email>",
        "route": "pl\/unsubscribe\/:email",
        "params": [
            "email"
        ]
    },
    "viat-shop\/redirect": {
        "original_route": "pl\/shop\/products",
        "route": "pl\/shop\/products",
        "params": []
    },
    "viat-shop\/detail": {
        "original_route": "pl\/shop\/order\/<id>",
        "route": "pl\/shop\/order\/:id",
        "params": [
            "id"
        ]
    },
    "viat-shop\/shop": {
        "original_route": "GET pl\/shop\/confirm-command\/<id>",
        "route": "pl\/shop\/confirm-command\/:id",
        "params": [
            "id"
        ]
    },
    "viat-shop\/new": {
        "original_route": "GET pl\/shop\/order\/<id>\/new",
        "route": "pl\/shop\/order\/:id\/new",
        "params": [
            "id"
        ]
    },
    "user-preferences\/auto-deposit": {
        "original_route": "pl\/auto-deposit",
        "route": "pl\/auto-deposit",
        "params": []
    },
    "workshop-booking\/new": {
        "original_route": "pl\/workshop-booking\/<idBooking:\\d+>\/new",
        "route": "pl\/workshop-booking\/:idBooking\/new",
        "params": [
            "idBooking"
        ]
    },
    "workshop-booking\/selection": {
        "original_route": "pl\/book-workshop\/<idProduct:\\d+>",
        "route": "pl\/book-workshop\/:idProduct",
        "params": [
            "idProduct"
        ]
    },
    "workshop-booking\/process": {
        "original_route": "pl\/confirm-workshop-booking\/<idBooking:\\d+>",
        "route": "pl\/confirm-workshop-booking\/:idBooking",
        "params": [
            "idBooking"
        ]
    },
    "glovie\/show-workshop-booking": {
        "original_route": "pl\/workshop-booking\/<idWorkshopBooking:\\d+>\/show",
        "route": "pl\/workshop-booking\/:idWorkshopBooking\/show",
        "params": [
            "idWorkshopBooking"
        ]
    },
    "site\/landing-workshop": {
        "original_route": "pl\/workshops",
        "route": "pl\/workshops",
        "params": []
    },
    "site\/viat": {
        "original_route": "pl\/viat",
        "route": "pl\/viat",
        "params": []
    },
    "site\/viat-parkings": {
        "original_route": "pl\/viat\/parkings",
        "route": "pl\/viat\/parkings",
        "params": []
    },
    "site\/viat-portugal": {
        "original_route": "pl\/viat\/portugal",
        "route": "pl\/viat\/portugal",
        "params": []
    },
    "site\/viat-discounts": {
        "original_route": "pl\/viat\/discounts",
        "route": "pl\/viat\/discounts",
        "params": []
    },
    "site\/viat-how-it-works": {
        "original_route": "pl\/viat\/how-it-works",
        "route": "pl\/viat\/how-it-works",
        "params": []
    },
    "site\/landing-how-to-park": {
        "original_route": "pl\/how-to-park",
        "route": "pl\/how-to-park",
        "params": []
    },
    "site\/landing-how-to-park-airport-parkings": {
        "original_route": "pl\/airport-parking",
        "route": "pl\/airport-parking",
        "params": []
    },
    "site\/landing-parking-sharing": {
        "original_route": "pl\/parking-sharing",
        "route": "pl\/parking-sharing",
        "params": []
    },
    "site\/landing-merchant-how-it-works": {
        "original_route": "pl\/redeem-coupons",
        "route": "pl\/redeem-coupons",
        "params": []
    },
    "site\/landing-merchant": {
        "original_route": "pl\/merchant",
        "route": "pl\/merchant",
        "params": []
    },
    "site\/landing-enterprise": {
        "original_route": "pl\/enterprise",
        "route": "pl\/enterprise",
        "params": []
    },
    "merchant\/index": {
        "original_route": "pl\/merchant\/account",
        "route": "pl\/merchant\/account",
        "params": []
    },
    "merchant\/shop": {
        "original_route": "pl\/merchant\/shop",
        "route": "pl\/merchant\/shop",
        "params": []
    },
    "merchant\/shop-step": {
        "original_route": "pl\/merchant\/shop\/step<id:\\d+>",
        "route": "pl\/merchant\/shop\/step:id",
        "params": [
            "id"
        ]
    },
    "coupon-order\/pdf": {
        "original_route": "pl\/merchant\/order\/<id:\\d+>.pdf",
        "route": "pl\/merchant\/order\/:id.pdf",
        "params": [
            "id"
        ]
    },
    "coupon-order\/render-new-pdf": {
        "original_route": "pl\/merchant\/order\/<id:\\d+>\/pdf",
        "route": "pl\/merchant\/order\/:id\/pdf",
        "params": [
            "id"
        ]
    },
    "merchant\/order": {
        "original_route": "pl\/merchant\/order\/<id:\\d+>\/detail",
        "route": "pl\/merchant\/order\/:id\/detail",
        "params": [
            "id"
        ]
    },
    "merchant\/sample-coupon": {
        "original_route": "pl\/merchant\/sample-coupon",
        "route": "pl\/merchant\/sample-coupon",
        "params": []
    },
    "wallet\/list": {
        "original_route": "GET pl\/wallet\/list",
        "route": "pl\/wallet\/list",
        "params": []
    },
    "booking\/pay-with-wallet": {
        "original_route": "PUT pl\/pay-booking\/<idBooking:\\d+>\/wallet\/<idWallet:\\d+>",
        "route": "pl\/pay-booking\/:idBooking\/wallet\/:idWallet",
        "params": [
            "idBooking",
            "idWallet"
        ]
    },
    "season-ticket-contract\/create-and-pay-with-wallet": {
        "original_route": "POST pl\/pay-season-ticket-contract\/wallet\/<idWallet:\\d+>",
        "route": "pl\/pay-season-ticket-contract\/wallet\/:idWallet",
        "params": [
            "idWallet"
        ]
    },
    "ser-ticket\/create-and-pay-with-wallet": {
        "original_route": "POST pl\/pay-ticket\/wallet\/<idWallet:\\d+>",
        "route": "pl\/pay-ticket\/wallet\/:idWallet",
        "params": [
            "idWallet"
        ]
    },
    "ser-fine\/pay-with-wallet": {
        "original_route": "POST pl\/pay-ser-fine\/wallet\/<idWallet:\\d+>",
        "route": "pl\/pay-ser-fine\/wallet\/:idWallet",
        "params": [
            "idWallet"
        ]
    },
    "vehicle-inspection-station\/show": {
        "original_route": "pl\/itv\/<slug>\/<id:\\d+>",
        "route": "pl\/itv\/:slug\/:id",
        "params": [
            "slug",
            "id"
        ]
    },
    "site\/landing-vehicle-inspection": {
        "original_route": "pl\/itv",
        "route": "pl\/itv",
        "params": []
    },
    "vehicle-inspection-station\/list": {
        "original_route": "pl\/search-vehicle-inspection-station",
        "route": "pl\/search-vehicle-inspection-station",
        "params": []
    },
    "glovie\/show-itv-booking": {
        "original_route": "GET pl\/vehicle-inspection-booking\/<idBooking:\\d+>\/show",
        "route": "pl\/vehicle-inspection-booking\/:idBooking\/show",
        "params": [
            "idBooking"
        ]
    },
    "glovie\/show-rotation": {
        "original_route": "GET pl\/rotation\/<idRotation:\\d+>\/show",
        "route": "pl\/rotation\/:idRotation\/show",
        "params": [
            "idRotation"
        ]
    },
    "glovie\/show-itv-booking-new": {
        "original_route": "GET pl\/vehicle-inspection-booking\/<idBooking:\\d+>\/new",
        "route": "pl\/vehicle-inspection-booking\/:idBooking\/new",
        "params": [
            "idBooking"
        ]
    },
    "parking\/show": {
        "original_route": "GET pl\/<province>\/<slug>\/<idParking:\\d+>",
        "route": "pl\/:province\/:slug\/:idParking",
        "params": [
            "province",
            "slug",
            "idParking"
        ]
    },
    "site\/landing-electric-charge": {
        "original_route": "pl\/electric-charge",
        "route": "pl\/electric-charge",
        "params": []
    },
    "kiosk\/reset-password": {
        "original_route": "pl\/en\/kiosk\/reset-password\/<token>",
        "route": "pl\/en\/kiosk\/reset-password\/:token",
        "params": [
            "token"
        ]
    },
    "on-boarding\/redirect": {
        "original_route": "pl\/on-boarding-ok",
        "route": "pl\/on-boarding-ok",
        "params": []
    },
    "site\/landing-petrol-station": {
        "original_route": "pl\/petrol-stations",
        "route": "pl\/petrol-stations",
        "params": []
    },
    "site\/landing-parkings": {
        "original_route": "pl\/parkings",
        "route": "pl\/parkings",
        "params": []
    },
    "poi\/show": {
        "original_route": "pl\/park-in-<townSlug>\/parking-<poiSlug>",
        "route": "pl\/park-in-:townSlug\/parking-:poiSlug",
        "params": [
            "townSlug",
            "poiSlug"
        ]
    },
    "poi\/show-events": {
        "original_route": "pl\/events-in-<poiId>\/<month:\\d+>",
        "route": "pl\/events-in-:poiId\/:month",
        "params": [
            "poiId",
            "month"
        ]
    },
    "poi\/show-vehicle-inspection-poi": {
        "original_route": "pl\/itv\/itv-booking-<poiSlug>",
        "route": "pl\/itv\/itv-booking-:poiSlug",
        "params": [
            "poiSlug"
        ]
    },
    "offered-parking-pass\/contract": {
        "original_route": "GET pl\/parking\/<idParking:\\d+>\/parking-pass\/<id:\\d+>",
        "route": "pl\/parking\/:idParking\/parking-pass\/:id",
        "params": [
            "idParking",
            "id"
        ]
    },
    "booked-parking-pass\/new": {
        "original_route": "GET pl\/parking-pass\/<id:\\d+>\/new",
        "route": "pl\/parking-pass\/:id\/new",
        "params": [
            "id"
        ]
    },
    "booked-parking-pass\/show": {
        "original_route": "GET pl\/parking-pass\/<id:\\d+>\/show",
        "route": "pl\/parking-pass\/:id\/show",
        "params": [
            "id"
        ]
    },
    "mas-movil\/refuse-lot-confirm": {
        "original_route": "POST pl\/masmovil\/refuse-lot\/<token>",
        "route": "pl\/masmovil\/refuse-lot\/:token",
        "params": [
            "token"
        ]
    },
    "mas-movil\/refuse-lot": {
        "original_route": "pl\/masmovil\/refuse-lot\/<token>",
        "route": "pl\/masmovil\/refuse-lot\/:token",
        "params": [
            "token"
        ]
    },
    "charging-point\/list": {
        "original_route": "pl\/search-electric-chage-station",
        "route": "pl\/search-electric-chage-station",
        "params": []
    },
    "glovie\/show-charging-point-booking": {
        "original_route": "GET pl\/charging-point-booking\/<idBooking:\\d+>\/show",
        "route": "pl\/charging-point-booking\/:idBooking\/show",
        "params": [
            "idBooking"
        ]
    },
    "driver-plus\/landing-fines": {
        "original_route": "pl\/driver-plus\/fines-not-notified",
        "route": "pl\/driver-plus\/fines-not-notified",
        "params": []
    },
    "driver-plus\/contract": {
        "original_route": "pl\/driver-plus\/contract",
        "route": "pl\/driver-plus\/contract",
        "params": []
    },
    "glovie\/driver-plus-subscriptions": {
        "original_route": "pl\/driver-plus\/subscriptions",
        "route": "pl\/driver-plus\/subscriptions",
        "params": []
    },
    "api\/wallet-corporate\/download-file": {
        "original_route": "GET pl\/wallets\/<walletId:\\d+>\/download-movements-csv\/<file>",
        "route": "pl\/wallets\/:walletId\/download-movements-csv\/:file",
        "params": [
            "walletId",
            "file"
        ]
    },
    "wallet-corporate-plates-whitelist\/download": {
        "original_route": "pl\/plates-whitelist\/<walletId:\\d+>\/download",
        "route": "pl\/plates-whitelist\/:walletId\/download",
        "params": [
            "walletId"
        ]
    },
    "rental-car\/show": {
        "original_route": "pl\/wynajmowac-samochody",
        "route": "pl\/wynajmowac-samochody",
        "params": []
    },
    "rental-car\/contract": {
        "original_route": "pl\/zako\u0144czy\u0107-wynajem-samochodu",
        "route": "pl\/zako\u0144czy\u0107-wynajem-samochodu",
        "params": []
    },
    "rental-car\/detail": {
        "original_route": "pl\/wynajmowac-samochody\/<id:\\d+>\/szczegol",
        "route": "pl\/wynajmowac-samochody\/:id\/szczegol",
        "params": [
            "id"
        ]
    },
    "rental-car\/search": {
        "original_route": "pl\/wynajmowac-samochody\/szukac",
        "route": "pl\/wynajmowac-samochody\/szukac",
        "params": []
    },
    "rental-car\/un-born": {
        "original_route": "pl\/wynajmowac-samochody\/post\/<unbornId:\\d+>\/<uuid>",
        "route": "pl\/wynajmowac-samochody\/post\/:unbornId\/:uuid",
        "params": [
            "unbornId",
            "uuid"
        ]
    },
    "rental-car\/un-born-share": {
        "original_route": "pl\/wynajmowac-samochody\/share\/<unbornId:\\d+>\/<uuid>",
        "route": "pl\/wynajmowac-samochody\/share\/:unbornId\/:uuid",
        "params": [
            "unbornId",
            "uuid"
        ]
    },
    "site\/index": {
        "original_route": "pl",
        "route": "pl",
        "params": []
    },
    "esp\/sitemap\/lang": {
        "original_route": "pl\/sitemap.xml",
        "route": "pl\/sitemap.xml",
        "params": []
    },
    "site\/home-redirect": {
        "original_route": "esp\/pl",
        "route": "esp\/pl",
        "params": []
    },
    "account\/on-boarding": {
        "original_route": "pl\/complete-profile",
        "route": "pl\/complete-profile",
        "params": []
    }
};