import Routes from '../routes/routes'
import { formatDay, parseTimestamp } from '../util/dateConverter'
import buildOptions from './buildApiOptions'
import { buildBookingError, mapBookingService } from './converters'
import { buildPostApi } from './helpers'
import {
    DATETIME_FORMAT_WITH_SECONDS,
} from 'commons/js/constants'
function mapBookingForNewUser (booking) {
    const result = {
        birthday: booking.birthday && booking.birthday.format('YYYY-MM-DD'),
        parkingId: booking.parkingId,
        productId: booking.bookingProductId,
        userName: booking.user.firstName,
        userLastName: booking.user.lastName,
        userPhone: booking.user.phone,
        userEmail: booking.user.email,
        numberPlate: booking.vehicle.numberPlate,
        start: formatDay(parseTimestamp(booking.startDate), DATETIME_FORMAT_WITH_SECONDS),
        end: formatDay(parseTimestamp(booking.endDate), DATETIME_FORMAT_WITH_SECONDS),
        promotionalCode: booking.promoCode,
        // Force new promotional code logic
        validatePromotionalCode: true,
        bookingAddedServices: booking.services?.filter(({serviceId}) => serviceId !== null).map(mapBookingService),
        setup_intent_id: booking.creditCard.id,
    }
    return result
}

function buildBookingService (service) {
    return {
        id: service.id,
        idBookingService: service.id_booking_service,
        idBookingServiceType: service.booking_service_type,
        transferTime: service.transfer_time,
        transferTimeFrequency: service.transfer_time_frequency,
        name: service.name,
        priceQty: service.price_qty,
        formFields: service.formFields,
    }
}

function buildBooking (booking) {
    return {
        id: booking.id,
        idExternal: booking.id_external,
        cancellable: booking.cancellable,
        cancelableLimitDate: booking.cancelableLimitDate,
        canExtend: booking.can_extend,
        startDate: booking.start_date,
        endDate: booking.end_date,
        bookingProduct: booking.bookingProduct,
        bookingInstructions: booking.booking_instructions,
        priceQty: booking.price_qty,
        priceBaseQty: booking.base_price_no_commission_qty,
        commissionQty: booking.commission_qty,
        parking: booking.parking,
        user: booking.user ? {
            firstName: booking.user.name,
            email: booking.user.email,
        } : null,
        vehicle: {
            ...booking.vehicle,
            numberPlate: booking.vehicle.number_plate,
        },
        bookingAddedServices: booking.bookingAddedServices.map(buildBookingService),
        isPostPayment: booking.isPostPayment,
        bookingPromotionalCode: booking.bookingPromotionalCode,
        promotionalCodePrice: booking.promotional_code_discounted_price,
        canEntryInAdvance: booking.can_entry_in_advance,
        entryInAdvanceMinutes: booking.entry_in_advance_minutes,
    }
}
export default buildPostApi({
    route: Routes.apiCreatePublicBooking,
    mapPayload: mapBookingForNewUser,
    buildResult: buildBooking,
    buildOptions,
    buildError: buildBookingError,
})
